import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import MultiSelect from '../../../hcare/components/MultiSelect';
import { removeChars } from '../../../helpers/hcutils';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropMultipleQuestion = ({ 
  question, 
  proposedanswers, 
  onChange, 
  invalid, 
  answer, 
  selectedExtendedDescription, 
  selectedOtherDetail 
}) => {

  const [selectedValues, setSelectedValues] = useState(answer || []); // Inicializa con answer si está disponible

  useEffect(() => {
    if ((!answer || answer.length === 0) && proposedanswers.length > 0) {
      setSelectedValues([]); // Inicializa el estado
    }
  }, [proposedanswers]);

  const handleChange = (selectedOptions) => {
    console.log("lolo");
    
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
  
    setSelectedValues(selectedValues); // Actualiza el array de valores seleccionados en el estado local
  
    const selectedExtendedDescriptions = selectedValues.map(value => {
      const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(value, 10));
      return selectedOption ? selectedOption.questionExtendedDescription || '' : '';
    });
  
    const selectedOtherDetails = selectedValues.map(value => {
      const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(value, 10));
      return selectedOption ? selectedOption.validOtherDetail || '' : '';
    });
  
    // Pasar la nueva información adicional al componente padre
    onChange({
      selectedValues,
      selectedExtendedDescription: selectedExtendedDescriptions.join(' | '),
      selectedOtherDetail: selectedOtherDetails.join(' | ')
    });
  };

  const formattedQuestion = removeChars(question);

  const options = proposedanswers.map(proposedanswer => ({
    value: proposedanswer.validAnswerId,
    label: proposedanswer.validAnswerText
  }));

  return (
    <FormGroup>
      {selectedExtendedDescription && (
        <>
          <div style={{ maxHeight: '10em', overflowY: 'auto', lineHeight: '1.5em', paddingRight: '10px' }}>
            <p className="mb-0">{selectedExtendedDescription}</p>
          </div>
          <hr style={{ margin: '1rem 0' }} />
        </>
      )}
      <Label for="selectOption" style={{ whiteSpace: 'pre-wrap', marginBottom: '1rem', display: 'block' }}>
        {formattedQuestion}
      </Label>
      <MultiSelect
        name="answer"
        id="selectOption"
        options={options}
        isMulti
        closeMenuOnSelect={false}
        classNamePrefix="react-select"
        value={options.filter(option => selectedValues.includes(option.value))}
        onChange={handleChange}
        className={invalid ? 'is-invalid' : ''}
      />
      {invalid && (
        <FormFeedback style={{ display: 'contents' }}>
          Please select at least one answer!
        </FormFeedback>
      )}
      {selectedOtherDetail && (
        <>
          <hr style={{ margin: '1rem 0' }} />
          <div style={{ maxHeight: '6em', overflowY: 'auto', lineHeight: '1.5em', paddingRight: '10px', marginTop: '1rem' }}>
            <p className="mb-0">{selectedOtherDetail}</p>
          </div>
        </>
      )}
    </FormGroup>
  );
};

DropMultipleQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  invalid: false,
  answer: '',
};

DropMultipleQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.arrayOf(
    PropTypes.shape({
      validAnswerId: PropTypes.number.isRequired,
      validAnswerText: PropTypes.string.isRequired,
      validOtherDetail: PropTypes.string,
      questionExtendedDescription: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  answer: PropTypes.any,
  selectedExtendedDescription: PropTypes.string,
  selectedOtherDetail: PropTypes.string,
};

export default DropMultipleQuestion;
