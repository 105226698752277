import React from 'react';
import { CustomInput, FormGroup } from 'reactstrap';

const RadioAnswer = props => {
  return (
    <FormGroup>
      <CustomInput
        type="radio"
        name={props.name}
        id={props.id + props.answerid}
        label={props.answer}
        inline={props.inline ? true : false}
        onChange={props.onChange}
        value={props.value}
        invalid={props.invalid}
        defaultChecked={props.checked}
        className="custom-radio-answer" // Añadir clase personalizada
      />
    </FormGroup>
  );
};

export default RadioAnswer;

RadioAnswer.defaultProps = {
  name: 'answerId',
  id: 'answerId'
};
