// user.service.js
import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from '../config';

const USER_API_URL = `${API_URL}/user`;

class UserService {
  // --- Métodos Relacionados con Usuarios ---

  /**
   * Obtiene contenido público.
   * @returns {Promise} - Promesa con los datos públicos.
   */
  getPublicContent() {
    return axios.get(`${USER_API_URL}/all`);
  }

  /**
   * Obtiene el tablero del usuario.
   * @returns {Promise} - Promesa con los datos del usuario.
   */
  getUserBoard() {
    return axios.get(`${USER_API_URL}/user`, { headers: authHeader() });
  }

  /**
   * Obtiene el tablero del administrador.
   * @returns {Promise} - Promesa con los datos del administrador.
   */
  getAdminBoard() {
    return axios.get(`${USER_API_URL}/admin`, { headers: authHeader() });
  }

  /**
   * Solicita un restablecimiento de contraseña.
   * @param {string} email - Correo electrónico del usuario.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  forgotPassword(email) {
    return axios
      .post(`${USER_API_URL}/forgot-password`, { email }, { headers: authHeader() })
      .then(response => response.data);
  }

  /**
   * Solicita la recuperación del nombre de usuario.
   * @param {string} email - Correo electrónico del usuario.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  forgotUsername(email) {
    return axios
      .post(`${USER_API_URL}/forgot-username`, { email }, { headers: authHeader() })
      .then(response => response.data);
  }

  /**
   * Restablece la contraseña del usuario.
   * @param {string} token - Token de restablecimiento.
   * @param {string} password - Nueva contraseña.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  passwordReset(token, password) {
    return axios.put(`${USER_API_URL}/reset-password`, { token, password }).then(response => response.data);
  }

  /**
   * Obtiene todos los usuarios.
   * @returns {Promise} - Promesa con la lista de usuarios.
   */
  all() {
    return axios.get(USER_API_URL, { headers: authHeader() });
  }

  /**
   * Obtiene todos los "clinicians" (testers).
   * @returns {Promise} - Promesa con la lista de testers.
   */
  clinicians() {
    return axios.get(`${USER_API_URL}/testers`, { headers: authHeader() });
  }

  /**
   * Obtiene todos los "testers" deshabilitados.
   * @returns {Promise} - Promesa con la lista de testers deshabilitados.
   */
  testersDisabled() {
    return axios.get(`${USER_API_URL}/testers_disabled`, { headers: authHeader() });
  }

  /**
   * Crea un nuevo usuario.
   * @param {Object} user - Datos del usuario.
   * @returns {Promise} - Promesa con los datos del usuario creado.
   */
  create(user) {
    const userData = {
      ...user,
      phoneNumber: user.phoneNumber || null,
      isRegisterClinician: false
    };
    return axios.post(USER_API_URL, userData, { headers: authHeader() }).then(response => response.data);
  }

  /**
   * Crea un nuevo "clinician" (tester).
   * @param {Object} user - Datos del tester.
   * @returns {Promise} - Promesa con los datos del tester creado.
   */
  createClinician(user) {
    const userData = {
      ...user,
      phoneNumber: user.phoneNumber || null,
      isRegisterClinician: false
    };
    return axios.post(`${USER_API_URL}/tester`, userData, { headers: authHeader() }).then(response => response.data);
  }

  /**
   * Elimina un usuario por ID.
   * @param {number|string} id - ID del usuario.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  delete(id) {
    return axios.delete(`${USER_API_URL}/${id}`, { headers: authHeader() });
  }

  /**
   * Actualiza un usuario existente.
   * @param {Object} user - Datos actualizados del usuario.
   * @returns {Promise} - Promesa con los datos del usuario actualizado.
   */
  update(user) {
    return axios.put(USER_API_URL, user, { headers: authHeader() }).then(response => response.data);
  }

  /**
   * Actualiza un "clinician" existente.
   * @param {Object} user - Datos actualizados del tester.
   * @returns {Promise} - Promesa con los datos del tester actualizado.
   */
  updateClinician(user) {
    return axios.put(`${USER_API_URL}/tester`, user, { headers: authHeader() }).then(response => response.data);
  }

  /**
   * Encuentra un usuario por ID.
   * @param {number|string} id - ID del usuario.
   * @returns {Promise} - Promesa con los datos del usuario.
   */
  find(id) {
    return axios.get(`${USER_API_URL}/${id}`, { headers: authHeader() });
  }

  /**
   * Encuentra un "clinician" por ID.
   * @param {number|string} id - ID del tester.
   * @returns {Promise} - Promesa con los datos del tester.
   */
  findClinician(id) {
    return axios.get(`${USER_API_URL}/tester/${id}`, { headers: authHeader() });
  }

  /**
   * Obtiene usuarios por departamento.
   * @param {number|string} departmentId - ID del departamento.
   * @returns {Promise} - Promesa con la lista de usuarios.
   */
  byDepartment(departmentId) {
    return axios.get(`${USER_API_URL}/bydepartment/${departmentId}`, { headers: authHeader() });
  }

  /**
   * Obtiene "clinicians" por departamento.
   * @param {number|string} departmentId - ID del departamento.
   * @returns {Promise} - Promesa con la lista de testers.
   */
  cliniciansByDepartment(departmentId) {
    return axios.get(`${USER_API_URL}/testers/bydepartment/${departmentId}`, { headers: authHeader() });
  }

  /**
   * Asigna bundles a usuarios.
   * @param {Object} bundles - Datos de los bundles.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  assignBundles(bundles) {
    return axios
      .post(`${USER_API_URL}/assignbundles`, bundles, { headers: authHeader() })
      .then(response => response.data);
  }

  /**
   * Actualiza la contraseña del usuario.
   * @param {string} oldPassword - Contraseña actual.
   * @param {string} newPassword - Nueva contraseña.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  updatePassword(oldPassword, newPassword) {
    const params = { oldPassword, newPassword };
    return axios
      .put(`${USER_API_URL}/update-password`, params, { headers: authHeader() })
      .then(response => response.data);
  }

  /**
   * Asigna exámenes a un usuario.
   * @param {number|string} userId - ID del usuario.
   * @param {boolean} isNotify - Si notificar al usuario.
   * @param {Array} exams - Lista de exámenes a asignar.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  assignExam(userId, isNotify, exams) {
    const params = { userId, isNotify, exams };
    return axios.post(`${USER_API_URL}/assignexam`, params, { headers: authHeader() }).then(response => response.data);
  }

  /**
   * Obtiene el modelo de usuario.
   * @returns {Promise} - Promesa con el modelo de usuario.
   */
  userModel() {
    return axios.get(`${USER_API_URL}/usermodel`, { headers: authHeader() });
  }

  /**
   * Restablece la contraseña de un usuario (ejecutado por un administrador).
   * @param {number|string} userId - ID del usuario.
   * @param {string} password - Nueva contraseña.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  password_Reset(userId, password) {
    const params = { userId, password };
    return axios
      .put(`${USER_API_URL}/password-reset`, params, { headers: authHeader() })
      .then(response => response.data);
  }

  /**
   * Actualiza el estado de deshabilitado de un usuario.
   * @param {number|string} id - ID del usuario.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  updateIsDisabled(id) {
    const url = `${USER_API_URL}/update-isdisabled/${id}`;
    return axios.put(url, { id }, { headers: authHeader() }).then(response => response.data);
  }

  // --- Métodos Relacionados con Contactos ---

  /**
   * Obtiene el contacto asociado a un assessment ID.
   * @param {number|string} assessmentId - ID del assessment.
   * @returns {Promise} - Promesa con los datos del contacto.
   */
  getContact(assessmentId) {
    return axios
      .get(`${USER_API_URL}/contact/${assessmentId}`, { headers: authHeader() })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  /**
   * Crea un nuevo contacto.
   * @param {Object} contactData - Datos del contacto.
   * @returns {Promise} - Promesa con los datos del contacto creado.
   */
  createContact(contactData) {
    return axios
      .post(`${USER_API_URL}/contact`, contactData, { headers: authHeader() })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  /**
   * Actualiza la información de un contacto existente.
   * @param {number|string} assessmentId - ID del assessment asociado al contacto.
   * @param {Object} contactData - Datos del contacto a actualizar.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  updateContact(assessmentId, contactData) {
    return axios
      .put(`${USER_API_URL}/contact/${assessmentId}`, contactData, { headers: authHeader() })
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  }

  /**
   * Guarda (crea o actualiza) un contacto basado en el assessment ID.
   * Si assessmentId es 0, crea un nuevo contacto.
   * Si assessmentId es distinto de 0, actualiza el contacto existente.
   * @param {number|string} assessmentId - ID del assessment.
   * @param {Object} contactData - Datos del contacto.
   * @returns {Promise} - Promesa con la respuesta del servidor.
   */
  saveContact(assessmentId, contactData) {
    if (parseInt(assessmentId, 10) === 0) {
      // Crear un nuevo contacto
      return this.createContact(contactData)
        .then(response => response)
        .catch(error => {
          throw error;
        });
    } else {
      // Actualizar un contacto existente
      return this.updateContact(assessmentId, contactData)
        .then(response => response)
        .catch(error => {
          throw error;
        });
    }
  }
}

export default new UserService();
