// UserGuidedForm.jsx
import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

const UserGuidedForm = ({
  form,
  handle_OnChange,
  errors
  // Otras props si es necesario
}) => (
  <form>
    {/* Primera fila: tres columnas con firstname, middlename, lastname */}
    <Row form>
      <Col md={4}>
        <FormGroup>
          <Label for="firstname">First Name</Label>
          <Input
            type="text"
            name="firstname"
            id="firstname"
            value={form.firstname}
            onChange={handle_OnChange}
            invalid={!!errors.firstname}
          />
          {errors.firstname && <div className="invalid-feedback d-block">{errors.firstname}</div>}
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="middlename">Middle Name</Label>
          <Input type="text" name="middlename" id="middlename" value={form.middlename} onChange={handle_OnChange} />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label for="lastname">Last Name</Label>
          <Input
            type="text"
            name="lastname"
            id="lastname"
            value={form.lastname}
            onChange={handle_OnChange}
            invalid={!!errors.lastname}
          />
          {errors.lastname && <div className="invalid-feedback d-block">{errors.lastname}</div>}
        </FormGroup>
      </Col>
    </Row>

    {/* Segunda fila: dos columnas con email y company */}
    <Row form>
      <Col md={6}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={form.email}
            onChange={handle_OnChange}
            invalid={!!errors.email}
          />
          {errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="company">Company</Label>
          <Input
            type="text"
            name="company"
            id="company"
            value={form.company}
            onChange={handle_OnChange}
            invalid={!!errors.company}
          />
          {errors.company && <div className="invalid-feedback d-block">{errors.company}</div>}
        </FormGroup>
      </Col>
    </Row>

    {/* Tercera fila: dos columnas con phone number y address */}
    <Row form>
      <Col md={6}>
        <FormGroup>
          <Label for="phoneNumber">Phone Number</Label>
          <Input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            value={form.phoneNumber}
            onChange={handle_OnChange}
            invalid={!!errors.phoneNumber}
          />
          {errors.phoneNumber && <div className="invalid-feedback d-block">{errors.phoneNumber}</div>}
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label for="address">Address</Label>
          <Input
            type="text"
            name="address"
            id="address"
            value={form.address}
            onChange={handle_OnChange}
            invalid={!!errors.address}
          />
          {errors.address && <div className="invalid-feedback d-block">{errors.address}</div>}
        </FormGroup>
      </Col>
    </Row>
  </form>
);

UserGuidedForm.propTypes = {
  form: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    middlename: PropTypes.string,
    lastname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    username: PropTypes.string,
    rolevalues: PropTypes.object,
    departmentvalue: PropTypes.object,
    specialtyId: PropTypes.number,
    organizationId: PropTypes.number,
    company: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired
  }).isRequired,
  handle_OnChange: PropTypes.func.isRequired,
  errors: PropTypes.object
  // Añade otras props según sea necesario
};

export default UserGuidedForm;
