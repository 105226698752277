// ExamList.jsx
import React, { useContext, useState, useEffect, Fragment } from 'react';
import {
  Card,
  CardBody,
  Badge,
  Button,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Loader from '../../../components/common/Loader';
import AppContext from '../../../context/Context';
import PageFilterableTable from '../PageFilterableTable';
import ExamDialog from '../question/examDialog';
import UserGuidedForm from '../user/userGuidedForm';
import HcareModal from './modal/HcareModal';
import { AssessmentTermsConditions } from './AssessmentTermsConditions';

import ExamService from '../../../services/exam/exam.service';
import UserService from '../../../services/user.service'; // Asegúrate de que este servicio existe
import { useHistory } from 'react-router-dom';
import { useHcareModal } from '../../hooks/useHcareModal';

// Subcomponentes optimizados con React.memo
const ExamResult = React.memo(({ row, onClick }) => (
  <Fragment>
    <Badge
      color={row.examScore.passed ? 'success' : 'danger'}
      className="fs--1 w-75"
      pill
      style={{ cursor: 'pointer' }}
      onClick={e => onClick(e, row)}
    >
      {row.examScore.clinicScore + ' '} {row.examScore.passed ? 'Pass' : 'Fail'}
    </Badge>
  </Fragment>
));

// Agregamos displayName para evitar el error de ESLint
ExamResult.displayName = 'ExamResult';

const CheckListResult = React.memo(({ row, onClick }) => (
  <Fragment>
    <Badge color="success" className="fs--1 w-75" pill style={{ cursor: 'pointer' }} onClick={e => onClick(e, row)}>
      {row.examScore ? ((row.examScore.frequency + row.examScore.proficiency) / 2).toFixed(1) : 'no data'}
    </Badge>
  </Fragment>
));

// Agregamos displayName para evitar el error de ESLint
CheckListResult.displayName = 'CheckListResult';

const ExamList = ({ setListHasChange, status }) => {
  const { currentuser } = useContext(AppContext);

  // Estados para la lista de exámenes
  const [isLoadedExamList, setIsLoadedExamList] = useState(false);
  const [examList, setExamList] = useState([]);
  const [organizationName, setOrganizationName] = useState('');

  // Estados para el diálogo del examen
  const [showExamDialog, setShowExamDialog] = useState(false);
  const [currentExam, setCurrentExam] = useState(null);

  // Estados para términos y condiciones
  const [isOpenTerms, openModalTerms, closeModalTerms] = useHcareModal(false);
  const [accept, setAccept] = useState(false);

  // Estados para el modal de "Add Contact"
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);

  // Estado para el formulario de UserGuidedForm
  const [form, setForm] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    email: '',
    username: '',
    rolevalues: null,
    departmentvalue: null,
    specialtyId: null,
    organizationId: -1,
    company: '',
    phoneNumber: '',
    address: ''
  });

  const [errors, setErrors] = useState({});

  const history = useHistory();

  // Función para alternar el modal de "Add Contact"
  const toggleAddContactModal = () => {
    setIsAddContactModalOpen(!isAddContactModalOpen);
  };

  // Función para manejar el clic en "Add Contact"
  const handle_AddContact = (e, row) => {
    e.preventDefault();
    e.stopPropagation(); // Evita que el evento burbujee y cierre el dropdown prematuramente
    setSelectedExam(row);

    // Si assessmentId es 0, se crea un nuevo contacto; de lo contrario, se actualiza
    const assessmentId = row.assessmentId || 0;

    if (parseInt(assessmentId, 10) !== 0) {
      // Obtener datos existentes del contacto
      UserService.getContact(assessmentId)
        .then(contact => {
          setForm({
            firstname: contact.firstName || '',
            middlename: contact.middleName || '',
            lastname: contact.lastName || '',
            email: contact.email || '',
            username: contact.username || '',
            rolevalues: contact.rolevalues || null,
            departmentvalue: contact.departmentvalue || null,
            specialtyId: contact.specialtyId || null,
            organizationId: contact.organizationId || -1,
            company: contact.company || '',
            phoneNumber: contact.phone || '',
            address: contact.address || ''
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            toast.info('No contact found for this assessment. You can create a new one.');
            // Si no se encuentra el contacto, limpiar el formulario
            setForm({
              firstname: '',
              middlename: '',
              lastname: '',
              email: '',
              username: '',
              rolevalues: null,
              departmentvalue: null,
              specialtyId: null,
              organizationId: -1,
              company: '',
              phoneNumber: '',
              address: ''
            });
          } else {
            toast.error(`Error fetching contact: ${error.response?.data?.message || error.message}`);
          }
        });
    } else {
      // Si assessmentId es 0, limpiar el formulario para crear uno nuevo
      setForm({
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        username: '',
        rolevalues: null,
        departmentvalue: null,
        specialtyId: null,
        organizationId: -1,
        company: '',
        phoneNumber: '',
        address: ''
      });
    }

    toggleAddContactModal();
  };

  // Función para manejar cambios en el formulario
  const handle_OnChange = e => {
    const { name, value } = e.target;
    setForm(prevForm => ({
      ...prevForm,
      [name]: value
    }));

    // Validaciones en tiempo real
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      switch (name) {
        case 'firstname':
          if (!value.trim()) {
            newErrors.firstname = 'First name is required';
          } else {
            delete newErrors.firstname;
          }
          break;
        case 'lastname':
          if (!value.trim()) {
            newErrors.lastname = 'Last name is required';
          } else {
            delete newErrors.lastname;
          }
          break;
        case 'email':
          if (!value) {
            newErrors.email = 'Email is required';
          } else if (!/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = 'Email is invalid';
          } else {
            delete newErrors.email;
          }
          break;
        case 'company':
          if (!value.trim()) {
            newErrors.company = 'Company is required';
          } else {
            delete newErrors.company;
          }
          break;
        case 'phoneNumber':
          if (!value.trim()) {
            newErrors.phoneNumber = 'Phone number is required';
          } else {
            delete newErrors.phoneNumber;
          }
          break;
        case 'address':
          if (!value.trim()) {
            newErrors.address = 'Address is required';
          } else {
            delete newErrors.address;
          }
          break;
        default:
          break;
      }
      return newErrors;
    });
  };

  // Función para manejar cambios en el rol (react-select)
  const handle_OnChangeRole = selectedOption => {
    setForm(prevForm => ({
      ...prevForm,
      rolevalues: selectedOption
    }));
  };

  // Función para manejar cambios en el departamento (react-select)
  const handle_OnChangeDepartment = selectedOption => {
    setForm(prevForm => ({
      ...prevForm,
      departmentvalue: selectedOption
    }));
  };

  // Función para establecer el valor del departamento (UserGuidedForm)
  const setDepartmentValue = department => {
    setForm(prevForm => ({
      ...prevForm,
      departmentvalue: department
    }));
  };

  // Función para manejar cambios en la organización
  const handle_OnChangeOrganization = e => {
    if (e) {
      const selectedOrganizationId = parseInt(e.target.value, 10);
      setForm(prevForm => ({
        ...prevForm,
        organizationId: selectedOrganizationId
      }));
      // Si es necesario, puedes implementar lógica adicional aquí
    }
  };

  // Función para manejar el cambio en la aceptación de términos
  const acceptHandle_OnChange = e => {
    if (e) {
      if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
        setAccept(e.target.checked);
      }
    }
  };

  // Función para renderizar el botón de aceptación
  const acceptRenderSubmitBtn = () => (
    <Fragment>
      <Button
        color="primary"
        disabled={!accept}
        onClick={() => {
          if (accept) {
            setShowExamDialog(!showExamDialog);
          }
          closeModalTerms();
        }}
      >
        Accept
      </Button>
    </Fragment>
  );

  // Función para cerrar el modal de términos
  const handle_OnClickCloseButton = () => {
    setAccept(false);
    closeModalTerms();
  };

  // Función para manejar el clic en un examen
  const handle_OnClick = (e, exam) => {
    e.preventDefault();
    if (exam.status.toLowerCase() === 'new') {
      openModalTerms();
    } else {
      setShowExamDialog(!showExamDialog);
    }
    setCurrentExam(exam);
  };

  // Función para mostrar el reporte del examen
  const handle_OnShowReport = (e, row) => {
    e.preventDefault();
    e.stopPropagation(); // Evita que el evento burbujee y cierre el dropdown prematuramente
    const examType = row.examType.toLowerCase();
    history.push(`/exam/report/${row.examId}/${currentuser.id}/${row.clinicianAssessmentId}/${examType}`);
  };

  /**
   * Función para manejar el envío del formulario de "Add Contact"
   * Utiliza el método saveContact del servicio UserService para decidir si crear o actualizar
   */
  const handle_SaveContact = e => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please fix the errors in the form');
      return;
    }

    const clinicianAssessmentId = selectedExam?.clinicianAssessmentId || 0;

    const contactData = {
      firstName: form.firstname,
      middleName: form.middlename,
      lastName: form.lastname,
      email: form.email,
      company: form.company,
      address: form.address,
      phone: form.phoneNumber
      // Añade otros campos según sea necesario
    };

    UserService.saveContact(clinicianAssessmentId, contactData)
      .then(response => {
        const successMessage =
          clinicianAssessmentId === 0 ? 'Contact created successfully' : 'Contact updated successfully';
        toast.success(successMessage);
        toggleAddContactModal();
        setForm({
          firstname: '',
          middlename: '',
          lastname: '',
          email: '',
          username: '',
          rolevalues: null,
          departmentvalue: null,
          specialtyId: null,
          organizationId: -1,
          company: '',
          phoneNumber: '',
          address: ''
        });
        setErrors({});
        // Opcional: Actualizar la lista de exámenes si es necesario
        getExamListFiltered(currentuser.id, status);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        const errorMessage =
          clinicianAssessmentId === 0
            ? `Error creating contact: ${resMessage}`
            : `Error updating contact: ${resMessage}`;
        toast.error(errorMessage);
      });
  };

  // Función para cancelar y cerrar el modal de "Add Contact"
  const cancelButton_OnClick = () => {
    toggleAddContactModal();
    setForm({
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      username: '',
      rolevalues: null,
      departmentvalue: null,
      specialtyId: null,
      organizationId: -1,
      company: '',
      phoneNumber: '',
      address: ''
    });
    setErrors({});
  };

  // Función para verificar si el usuario es super usuario
  const isSuperUser = () => {
    return form.role && form.role.includes('super');
  };

  // Función para verificar si el usuario es administrador
  const isAdminUser = () => {
    return form.role && form.role.includes('admin');
  };

  // Función para verificar si el usuario es un usuario normal
  const isUser = () => {
    return form.role && form.role.includes('user');
  };

  // Función para verificar si existen agencias (compañías)
  const existAgency = () => {
    return organizationName.length > 0; // Ajusta según tu lógica
  };

  // Función para verificar si existen grupos (departamentos)
  const existGroup = () => {
    if (isSuperUser()) {
      return true;
    }
    return form.departmentvalue !== null;
  };

  // Función para mostrar el formulario si se cumplen las condiciones
  const showForm = () => {
    return isLoadedExamList && existAgency() && existGroup();
  };

  // Función para mostrar mensajes cuando faltan datos
  const youMustMessage = () => {
    if (!existAgency() && !existGroup()) {
      return <h5>You must create a company and a department</h5>;
    } else if (!existAgency()) {
      return <h5>You must create a company</h5>;
    } else if (!existGroup()) {
      return <h5>You must create a department</h5>;
    }
  };

  // Función para obtener la lista de exámenes filtrada
  const getExamListFiltered = async (userid, examstatus) => {
    try {
      const [examsResponse, userResponse] = await Promise.all([
        ExamService.allByUserFiltered(userid, examstatus),
        UserService.find(userid)
      ]);
      setExamList(examsResponse);
      setOrganizationName(userResponse.data.department.organization.organizationName);
      setIsLoadedExamList(true);
    } catch (error) {
      const resMessage =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      toast.error(`${resMessage}`);
    }
  };

  // useEffect para cargar la lista de exámenes al montar o cuando cambie el diálogo
  useEffect(() => {
    if (!showExamDialog) {
      setIsLoadedExamList(false);
      getExamListFiltered(currentuser.id, status);
      setListHasChange(true);
    }
  }, [showExamDialog, setListHasChange, status, currentuser.id]);

  // Formateadores para las columnas de la tabla
  const stateFormatter = (dataField, row) => {
    if (row) {
      switch (row.status.toLowerCase()) {
        case 'new':
          return (
            <Badge color="soft-primary" className="fs--1" pill>
              New
            </Badge>
          );
        case 'initiated':
          return (
            <Badge color="soft-info" className="fs--1" pill>
              Initiated
            </Badge>
          );
        case 'in progress':
          return (
            <Badge color="soft-warning" className="fs--1" pill>
              In progress
            </Badge>
          );
        case 'complete':
          return (
            <Badge color="soft-success" className="fs--1" pill>
              Completed
            </Badge>
          );
        default:
          return (
            <Badge color="soft-danger" className="fs--1" pill>
              Undefined
            </Badge>
          );
      }
    }
    return null;
  };

  const actionFormatter = (dataField, row) => {
    const disabled = row.examQuestionCount === null || row.examQuestionCount <= 1;

    if (row) {
      switch (row.status.toLowerCase()) {
        case 'new':
          return (
            <Button
              color={`falcon-${disabled ? 'dark' : 'success'}`}
              disabled={disabled}
              onClick={e => handle_OnClick(e, row)}
            >
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        case 'initiated':
          return (
            <Button color={`falcon-${disabled ? 'dark' : 'success'}`} disabled={disabled}>
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        case 'in progress':
          return (
            <Button
              color={`falcon-${disabled ? 'dark' : 'success'}`}
              disabled={disabled}
              onClick={e => handle_OnClick(e, row)}
            >
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        case 'complete':
          return (
            <Fragment>
              {row.examType.toLowerCase() === 'exam' && <ExamResult row={row} onClick={handle_OnShowReport} />}
              {row.examType.toLowerCase() === 'checklist' && (
                <CheckListResult row={row} onClick={handle_OnShowReport} />
              )}
            </Fragment>
          );
        default:
          return <Badge color="soft-danger" className="mr-2" pill />;
      }
    }
    return null;
  };

  const viewFormatter = (dataField, row) => {
    if (row && row.status.toLowerCase() === 'complete') {
      const examTypeLower = row.examType.toLowerCase();
      if (examTypeLower === 'form' || examTypeLower === 'guided') {
        return (
          <UncontrolledDropdown>
            <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3" aria-label="More options">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
            </DropdownToggle>
            <DropdownMenu right className="border py-2">
              <DropdownItem onClick={e => handle_OnShowReport(e, row)}>
                <Badge color="info" className="fs--1" pill style={{ cursor: 'pointer' }}>
                  View
                </Badge>
              </DropdownItem>
              <DropdownItem onClick={e => handle_AddContact(e, row)}>
                <Badge
                  color="success" // Verde
                  className="fs--1"
                  pill
                  style={{ cursor: 'pointer' }}
                >
                  Add Contact
                </Badge>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      } else {
        return (
          <Badge
            color="info"
            className="ml-2 fs--1"
            pill
            style={{ cursor: 'pointer' }}
            onClick={e => handle_OnShowReport(e, row)}
          >
            View
          </Badge>
        );
      }
    }
    return null;
  };

  const columns = [
    {
      dataField: 'examName',
      headerClasses: 'border-0',
      text: 'Description',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: stateFormatter,
      align: 'center',
      headerAlign: 'center',
      sort: true
    },
    {
      dataField: 'examscore',
      headerClasses: 'border-0 ',
      text: 'Score %',
      classes: 'border-0 py-2 align-middle font-weight-bold',
      align: 'center',
      headerAlign: 'center',
      formatter: actionFormatter
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-right',
      text: 'Report',
      classes: 'border-0 py-2 align-middle text-right font-weight-bold',
      formatter: viewFormatter
    }
  ];

  // Función para manejar la visibilidad del ExamDialog
  const setExamDialogVisible = value => {
    setShowExamDialog(value);
  };

  // Función de validación
  const validateForm = () => {
    const newErrors = {};

    if (!form.firstname.trim()) {
      newErrors.firstname = 'First name is required';
    }
    if (!form.lastname.trim()) {
      newErrors.lastname = 'Last name is required';
    }
    if (!form.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!form.company.trim()) {
      newErrors.company = 'Company is required';
    }
    if (!form.phoneNumber.trim()) {
      newErrors.phoneNumber = 'Phone number is required';
    }
    if (!form.address.trim()) {
      newErrors.address = 'Address is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Renderizado del componente
  return (
    <Fragment>
      {/* Modal de Términos y Condiciones */}
      <HcareModal
        title={'Terms & Conditions - Candidate Rights: (tester)'}
        isOpen={isOpenTerms}
        closeModal={handle_OnClickCloseButton}
        submitbtn={acceptRenderSubmitBtn}
        className="terms-conditions"
      >
        <AssessmentTermsConditions />
        <FormGroup className="form-check" style={{ marginTop: '2rem' }}>
          <Input type="checkbox" name="accept" id="accept" checked={accept} onChange={acceptHandle_OnChange} />
          <Label for="accept" check>
            I agree to terms and conditions of escoreit software
          </Label>
        </FormGroup>
      </HcareModal>

      {/* Modal para "Add Contact" */}
      <Modal isOpen={isAddContactModalOpen} toggle={toggleAddContactModal} size="lg">
        <ModalHeader toggle={toggleAddContactModal}>
          {selectedExam?.assessmentId === 0 ? 'Create Contact' : 'Update Contact'}
        </ModalHeader>
        <ModalBody>
          {selectedExam && (
            <UserGuidedForm
              form={form}
              handle_OnChange={handle_OnChange}
              errors={errors}
              // Pasa otras props necesarias si es necesario
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="falcon-primary" className="mr-2" type="submit" onClick={handle_SaveContact}>
            {selectedExam?.assessmentId === 0 ? 'Create' : 'Update'}
          </Button>
          <Button color="falcon-default" type="button" onClick={cancelButton_OnClick}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Dialogo del Examen */}
      <ExamDialog
        visible={showExamDialog}
        setVisible={setExamDialogVisible}
        user={currentuser}
        exam={currentExam}
        backdrop={'static'}
      />

      {/* Loader mientras se carga la lista de exámenes */}
      {!isLoadedExamList && <Loader />}

      {/* Tabla de Exámenes */}
      {isLoadedExamList && currentuser.roles.includes('user') && (
        <Card className="mb-3 table-container">
          <CardBody className="bg-light">
            <PageFilterableTable
              data={examList}
              columns={columns}
              keyField="clinicianAssessmentId"
              title={organizationName}
              enableNewButton={false}
              sizePerPage={8}
            />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

// Validación de Props
ExamList.propTypes = {
  setListHasChange: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default ExamList;
